import React, { Component } from 'react'
import { Line } from 'react-chartjs-2'
import { ChartOptions } from 'chart.js'
import { ColourLookup } from '../../utils'
import './Chart.sass'

type MyProps = {
  timeseries: undefined | {
    dates: [string],
    values: [number],
  }
}

function getPointColours(points: any) {
  if(points) {
    return points.map((point: number) => ColourLookup(point))
  } else {
    return undefined
  }
}

class Chart extends Component<MyProps>{

  render() {  

    const timeseries: any = this.props.timeseries

    const template = {
      fill: false,
      lineTension: 0,
    }

    const data = {
      labels: timeseries?.dates,
      datasets: [{
        ...template, 
        label: 'Simulated',
        borderColor: '#999',
        data: timeseries?.values,
        backgroundColor: getPointColours(timeseries?.values),
        pointRadius: 5,
        borderWidth: 1
      }]
    }

    const endDate = (timeseries: any) => {
      if(timeseries){
        let endDate = new Date(timeseries.dates[timeseries.dates.length - 1])
        endDate.setDate(endDate.getDate() + 1) //Add a day to just extend the x-axis slightly
        return endDate.toISOString()
      }
    }

    const options: ChartOptions = {
      plugins: {
        tooltip: {
          callbacks: {
            label: (e: any) => {
              console.log(e)
              return 'rf'
            }
          }
        }
      },
      legend: {
        display: false,
      },
      tooltips: {
        enabled: true,
        displayColors: false,
        callbacks: {
          title: (e: any) => {
            return e[0].label.slice(0,10)
            // Just in case the requirement changes, this is how to formate the last date differently to the others
            // Is this the last date?  If yes it will need formatting as yyyy-mm-dd, if not it will just need yyyy-mm
            // const isLastDate = e[0].index === timeseries?.dates.length - 1
            // if(isLastDate){
            //   return e[0].label.slice(0,10)
            // } else {
            //   return e[0].label.slice(0,7)
            // }
          },
          label: (e: any) => {
            return `Relative wetness: ${e.yLabel} %`
          }
        }
    },
      scales: {
        xAxes: [{
          type: 'time',
          time: {
            unit: 'month'
          },
          ticks: {
            min: timeseries?.dates[0],
            max: endDate(timeseries)
          }
        }],
        yAxes: [{
          ticks: {
            min: -200,
            max: 200,
            maxTicksLimit: 5
          },
          scaleLabel: {
            display: true,
            labelString: '% relative wetness',
          }
        }]
      }
    }

    return <div>
        <Line data={data} options={options} />
        <p className='chart-footer-text'>Locations that are wetter than average (% values above 0) for that day are shown in blue/black, locations that are drier than average (% values below 0) are shown in yellow/red.</p>
        <p className='chart-footer-text'>Values of +100% (-100%) show a location’s estimated soil store is equal to the maximum (minimum) daily mean soil store observed on that day between 1965 and 2020, estimated for the <u>1km grid cell</u> in which that location lies.  Values above 100% or below -100% are thus very wet/dry but are not necessarily record-breaking.</p>
      </div>
  }
}

export default Chart
