import React, { useState, useEffect } from 'react'
import { IonCard, IonPage, IonContent, IonCardContent } from '@ionic/react'
import { WMSTileLayer } from 'react-leaflet'
import Control from 'react-leaflet-control'
import { Legend, OsMap, Transparency, LocationButton } from '../../components'
import { ColourLookup } from '../../utils'
import { Chart } from '../../components'
import { Footer } from '../../components'
import { Plugins } from '@capacitor/core';
import proj4 from 'proj4'
import './Map.sass'
import Splash from '../../components/Splash/Splash'

const { Geolocation } = Plugins;

const projName = 'EPSG:27700'
const projParams = '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 +x_0=400000 +y_0=-100000 +ellps=airy +datum=OSGB36 +units=m +no_defs'
proj4.defs(projName,projParams)

const Map: React.FC = (props: any) => {

  const defaultZoomLevel = 9

  const [date, setDate] = useState('')
  const [transparency, setTransparency] = useState(80)
  const [center, setCenter] = useState<[number,number]>([51.7, -1.5])
  const [zoomLevel, setZoomLevel] = useState(defaultZoomLevel)
  const [timeseries, setTimeseries] = useState(undefined)
  const [currentVal, setCurrentVal] = useState('')
  const [updated, setUpdated] = useState('')
  const [isLocated, setIsLocated] = useState(false)
  const [gridref, setGridref] = useState<{x:number,y:number} | undefined>(undefined)

  function currentColour() {
    const moistureVal = parseInt(currentVal)
    switch(true) {
      case moistureVal > 30:
        return '#ffffff'
      case moistureVal > -50:
        return '#000000'
        case moistureVal < -50:
          return '#ffffff'
      }
  }

  function getTimeseries(location: {x:number, y:number}): void {

    // Takes a date of the form yyyy-mm-ddThh:mm:ssZ and returns yyyy-mm-dd
    const formatDate = (to_format: string) => {
      return `${to_format.slice(0,4)}-${to_format.slice(5,7)}-${to_format.slice(8,10)}`
    }

    fetch(`/api/timeseries/${location.x.toString()}/${location.y.toString()}`)
    .then(response => {
      if (!response.ok) {
        throw new Error(response.statusText)
      }
      return response.json() as Promise<any>
    }).then(response => {
      if(response.error.haserror){
        console.log(response.error.message)
      } else {
        setDate(formatDate(response.data.dates[(response.data.values.length - 1)]))
        setTimeseries(response.data)
        setGridref({x: parseInt(response.x), y: parseInt(response.y)})
        setCurrentVal(response.data.values[response.data.values.length - 1])
        setUpdated(response.updated)
      }
    })
  }

  function locationButtonSetTimeseries(location: {x:number, y:number}): void {
    fetch(`/api/timeseries/${location.x.toString()}/${location.y.toString()}`)
      .then(response => {
        if (!response.ok) {
          throw new Error(response.statusText)
        }
        return response.json() as Promise<any>
      }).then(response => {
        if(response.error.haserror){
          console.log(response.error.message)
        } else {
          setTimeseries(response.data)
          setCurrentVal(response.data.values[response.data.values.length - 1])
        }
      })
  }
  
  useEffect(() => {
    const defaultLocation: [number,number] = [51.5074, -0.1278]

    function setCurrentLocation(coordinates: [number, number]){
      setZoomLevel(defaultZoomLevel);
      setCenter(coordinates);
  
      const converter = proj4('EPSG:27700')
      const bngpoint = converter.forward([coordinates[1], coordinates[0]])
      getTimeseries({x:bngpoint[0], y:bngpoint[1]})
      setIsLocated(true)
    }

    const getCurrentLocation = async () => {
      try {
        const coordinates = await Geolocation.getCurrentPosition();
        setCurrentLocation([coordinates.coords.latitude, coordinates.coords.longitude])
      } catch(err) {
        console.log(`Location is blocked, using default location: ${defaultLocation}`)
        setCurrentLocation(defaultLocation)
      }
    }
    getCurrentLocation()
  },[isLocated])

return <IonPage className="map-page">
  <IonContent>
    <Splash />
    <IonCard>
      <IonCardContent className='wetness-value'>
        <div>
        <p className='chart-title title-head'>Last updated: {updated}</p>
        <p className='chart-title title-body'>Soil wetness on {date}</p>
          <div style={{'backgroundColor': ColourLookup(parseInt(currentVal)), color: currentColour()}}>{currentVal}%</div>
          <p className='chart-title title-sub'>Grid reference: {gridref?.x}, {gridref?.y}</p>
        </div>
    </IonCardContent>
    </IonCard>
    <IonCard>
      <IonCardContent className="map-item">
        <OsMap center={center} zoomLevel={zoomLevel} updateCenter={setCenter} updateZoomLevel={setZoomLevel} updateLocation={getTimeseries} hasMarker={true}>
          <LocationButton locationAction={setCenter} zoomAction={setZoomLevel} setTimeseries={locationButtonSetTimeseries} />
          <WMSTileLayer
            layers={'moisture'}
            url={`/mapserver/mapserv?map=/maps/moisture50m.map`}
            maxZoom={6}
            transparent={true}
            format='image/png'
            opacity={(transparency/100)}
          />
          <WMSTileLayer
            layers={'moisture'}
            url={`/mapserver/mapserv?map=/maps/moisture50m.map`}
            minZoom={7}
            transparent={true}
            format='image/png'
            opacity={(transparency/100)}
          />
          <Control position='topleft'>
            <Legend />
          </Control>
          <Control position='bottomleft'>
            <Transparency transparency={(transparency as number)} setTransparency={setTransparency}/>
          </Control>
        </OsMap>
      </IonCardContent>
    </IonCard>
    <IonCard>
      <IonCardContent>
        <Chart timeseries={timeseries} />
      </IonCardContent>
    </IonCard>
    </IonContent>
    <Footer />
  </IonPage>
}

export default Map
