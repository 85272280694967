import React from 'react';
import './Legend.sass'

const Legend = () => {
  const colours = {
    a: '#000000',
    b: '#302B91',
    c: '#0070C0',
    d: '#74BAFF',
    e: '#DBEEF4',
    f: '#FFFFFF',
    g: '#C00000',
    h: '#FF3300',
    i: '#FFC000',
    j: '#FFFF00',
    k: '#FFFFCC',
    l: '#FFFFFF'
  }
  return (
    <div className='map-legend' style={{'backgroundImage': `linear-gradient(
      ${colours.a},
      ${colours.b},
      ${colours.c},
      ${colours.d},
      ${colours.e},
      ${colours.f},
      ${colours.k},
      ${colours.j},
      ${colours.i},
      ${colours.h},
      ${colours.g})`
    }}>
      <div className='legend-text'>Wet</div>
      <div className='legend-text legend-middle'>Average</div>
      <div className='legend-text legend-bottom'>Dry</div>
    </div>
  )

}

export default Legend;
