import { IonCardContent, IonCardTitle, IonCard, IonCardHeader, IonContent, IonPage } from '@ionic/react'
import React from 'react'
import './Info.sass'
import { Footer } from '../../components'
import Splash from '../../components/Splash/Splash'

const Home: React.FC = () => {
  return (
    <IonPage className='info'>
      <IonContent fullscreen>
        <Splash />
        <IonCard>
          <IonCardHeader>
            <IonCardTitle class="ion-text-center">
            Soil Wetness Explorer App Info
            </IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <p>The maps in this app display <b>Soil Wetness</b> (relative soil moisture) as a difference or ‘anomaly’ from the historical mean for each day: this is a model estimate of how wet or dry the soil is, relative to historical extremes for the time of year.</p>
            <p><b>Soil Wetness</b> is presented as a % value relative to local (<u>1km</u>) historical maximum (wetness of +100%) and minimum (wetness of -100%) estimates for each day.</p>
            <p>The “wetness/dryness” moisture products displayed here are produced alongside the Hydrological Outlook (HOUK) by a collaboration led by the UK Centre for Ecology & Hydrology (UKCEH) and involving the Met Office. For more information about the HOUK and wetness/dryness maps please visit <a href='http://www.hydoutuk.net/current-conditions'>http://www.hydoutuk.net/current-conditions</a>.</p>
            <p>Some of the features displayed on the maps contained in this report are based on the following data with permission of the controller of HMSO.</p>
            <ul>
              <li><h2>Ordnance Survey data</h2>© Crown copyright and/or database right 2005. Licence no. 100017897.</li>
              <li><h2>MasterMap Topography Layer, Ordnance Survey</h2>© Crown copyright and database rights 2020 OS 0100047213.</li>
              <li><h2>Met Office HadUK-grid rainfall and temperature data</h2>Open Government Licence v3.0.</li>
              <li><h2>OS OpenData</h2>© Crown copyright 2024.</li>
            </ul>
            <p>All rights reserved. Unauthorised reproduction infringes Crown copyright and may lead to prosecution or civil proceedings.</p>
          </IonCardContent>
        </IonCard>
      </IonContent>
      <Footer />
    </IonPage>
  )
}

export default Home
