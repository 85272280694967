import React from 'react';
import './Splash.sass'
import { IonCard, IonItem, IonLabel } from '@ionic/react'

const Splash = () => {
  return (
    <IonCard>
      <IonItem lines='none' color='warning'>
        <IonLabel>
          Now updated daily!
        </IonLabel>
      </IonItem>
    </IonCard>
  )
}

export default Splash;
