const ColourLookup = (moistureVal: number) => {
  
  function zeroPad(val: string) {
    return (val.length === 1) ? `0${val}` : val
  }
  
  // Calculate the hex colour that lies along the startHex to endHex colour for the val assuming a classSize of 25
  function lookupColour (val: number, lowerClassVal: number, startHex: string, endHex: string){
    const classSize = 25
    const r1 = parseInt(startHex.substring(0,2), 16)
    const g1 = parseInt(startHex.substring(2,4), 16)
    const b1 = parseInt(startHex.substring(4,6), 16)
    const r2 = parseInt(endHex.substring(0,2), 16)
    const g2 = parseInt(endHex.substring(2,4), 16)
    const b2 = parseInt(endHex.substring(4,6), 16)
    const proportion = Math.abs((val % classSize) / classSize)
    let red = Math.floor(r1 + ((r2 - r1) * proportion)).toString(16)
    let green = Math.floor(g1 + ((g2 - g1) * proportion)).toString(16)
    let blue = Math.floor(b1 + ((b2 - b1) * proportion)).toString(16)
    return `#${zeroPad(red)}${zeroPad(green)}${zeroPad(blue)}`
  }
  
  function getBackgroundColour(moistureVal: number) {
    switch(true) {
      case moistureVal >= 100:
        return '#000000'
      case moistureVal >= 75:
        return lookupColour(moistureVal, 75, '302B91', '000000')
      case moistureVal >= 50:
        return lookupColour(moistureVal, 50, '0070C0', '302B91')
      case moistureVal >= 25:
        return lookupColour(moistureVal, 25, '74BAFF', '0070C0')
      case moistureVal >= 0:
        return lookupColour(moistureVal, 0, 'FFFFFF', '74BAFF')
      case moistureVal >= -24:
        return lookupColour(moistureVal, -25, 'FFFFFF', 'FFFF00')
      case moistureVal >= -49:
        return lookupColour(moistureVal, -50, 'FFFF00', 'FFC000')
      case moistureVal >= -74:
        return lookupColour(moistureVal, -75, 'FFC000', 'FF3300')
      case moistureVal >= -99:
        return lookupColour(moistureVal, -100, 'FF3300', 'C00000')
      case moistureVal < -99:
        return '#C00000'
    }
  }

  return getBackgroundColour(moistureVal)
}

export default ColourLookup