import React, { useRef, useEffect } from 'react'

import * as L from 'leaflet'
import 'proj4leaflet'
import proj4 from 'proj4'
import { ZoomControl, Map, TileLayer, Marker } from 'react-leaflet'
import { Icon } from 'leaflet'
import 'leaflet/dist/leaflet.css'
import './OsMap.css'

  const projName = 'EPSG:27700'
  const projParams = '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 +x_0=400000 +y_0=-100000 +ellps=airy +datum=OSGB36 +units=m +no_defs'
  const crs27700 = new L.Proj.CRS(projName,
    projParams,
    {
      resolutions: [896, 448, 224, 112, 56, 28, 14, 7, 3.5, 1.75, 0.875, 0.4375, 0.21875, 0.109375],
      origin: [-238375, 1376256]
    }
  )
  proj4.defs(projName,projParams)
  
interface MapProps {
  center: any
  updateCenter: any
  zoomLevel: any
  updateZoomLevel: any
  updateLocation?: any
  hasMarker: boolean
  children: any
}

const OsMap: React.FC<MapProps> = (props) => {

  const url = 'https://api.os.uk/maps/raster/v1/zxy/Outdoor_27700/{z}/{x}/{y}.png?key=y3peTmMMehu5MGOQ94NXyo60ZKUhBZQF'

  const mapEl = useRef<Map>(null)

  useEffect(() => {
    if (mapEl.current) {
      // Map only partially appears - here's why with best fix so far
      // Good explanation https://stackoverflow.com/questions/36246815/data-toggle-tab-does-not-download-leaflet-map/36257493#36257493
      // Only the timeout seems to sort it out https://stackoverflow.com/questions/56364129/vue2-leaflet-map-not-showing-properly-in-boostrapvue-modal/56364130#56364130
      setTimeout(() => {
        if(mapEl.current) {
          mapEl.current.leafletElement.invalidateSize()
        }
      }, 200)
    }
  })

  const handleMove = (e: any) => {
    const coords = e.target.getCenter()
    const zoom = e.target.getZoom()
    props.updateCenter([coords.lat,coords.lng])
    props.updateZoomLevel(zoom)
  }

  const handleDragEnd = (e: any) => {
    if(props.updateLocation){
      try{
        const converter = proj4('EPSG:27700')
        const bngpoint = converter.forward([props.center[1],props.center[0]])
        props.updateLocation({x:bngpoint[0], y:bngpoint[1]})
      }
      catch(err){
        console.log('error ondragend')
        console.log(err)
      }
    }
  }

  const icon = new Icon({
    iconUrl:'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAApCAYAAADAk4LOAAAFgUlEQVR4Aa1XA5BjWRTN2oW17d3YaZtr2962HUzbDNpjszW24mRt28p47v7zq/bXZtrp/lWnXr337j3nPCe85NcypgSFdugCpW5YoDAMRaIMqRi6aKq5E3YqDQO3qAwjVWrD8Ncq/RBpykd8oZUb/kaJutow8r1aP9II0WmLKLIsJyv1w/kqw9Ch2MYdB++12Onxee/QMwvf4/Dk/Lfp/i4nxTXtOoQ4pW5Aj7wpici1A9erdAN2OH64x8OSP9j3Ft3b7aWkTg/Fm91siTra0f9on5sQr9INejH6CUUUpavjFNq1B+Oadhxmnfa8RfEmN8VNAsQhPqF55xHkMzz3jSmChWU6f7/XZKNH+9+hBLOHYozuKQPxyMPUKkrX/K0uWnfFaJGS1QPRtZsOPtr3NsW0uyh6NNCOkU3Yz+bXbT3I8G3xE5EXLXtCXbbqwCO9zPQYPRTZ5vIDXD7U+w7rFDEoUUf7ibHIR4y6bLVPXrz8JVZEql13trxwue/uDivd3fkWRbS6/IA2bID4uk0UpF1N8qLlbBlXs4Ee7HLTfV1j54APvODnSfOWBqtKVvjgLKzF5YdEk5ewRkGlK0i33Eofffc7HT56jD7/6U+qH3Cx7SBLNntH5YIPvODnyfIXZYRVDPqgHtLs5ABHD3YzLuespb7t79FY34DjMwrVrcTuwlT55YMPvOBnRrJ4VXTdNnYug5ucHLBjEpt30701A3Ts+HEa73u6dT3FNWwflY86eMHPk+Yu+i6pzUpRrW7SNDg5JHR4KapmM5Wv2E8Tfcb1HoqqHMHU+uWDD7zg54mz5/2BSnizi9T1Dg4QQXLToGNCkb6tb1NU+QAlGr1++eADrzhn/u8Q2YZhQVlZ5+CAOtqfbhmaUCS1ezNFVm2imDbPmPng5wmz+gwh+oHDce0eUtQ6OGDIyR0uUhUsoO3vfDmmgOezH0mZN59x7MBi++WDL1g/eEiU3avlidO671bkLfwbw5XV2P8Pzo0ydy4t2/0eu33xYSOMOD8hTf4CrBtGMSoXfPLchX+J0ruSePw3LZeK0juPJbYzrhkH0io7B3k164hiGvawhOKMLkrQLyVpZg8rHFW7E2uHOL888IBPlNZ1FPzstSJM694fWr6RwpvcJK60+0HCILTBzZLFNdtAzJaohze60T8qBzyh5ZuOg5e7uwQppofEmf2++DYvmySqGBuKaicF1blQjhuHdvCIMvp8whTTfZzI7RldpwtSzL+F1+wkdZ2TBOW2gIF88PBTzD/gpeREAMEbxnJcaJHNHrpzji0gQCS6hdkEeYt9DF/2qPcEC8RM28Hwmr3sdNyht00byAut2k3gufWNtgtOEOFGUwcXWNDbdNbpgBGxEvKkOQsxivJx33iow0Vw5S6SVTrpVq11ysA2Rp7gTfPfktc6zhtXBBC+adRLshf6sG2RfHPZ5EAc4sVZ83yCN00Fk/4kggu40ZTvIEm5g24qtU4KjBrx/BTTH8ifVASAG7gKrnWxJDcU7x8X6Ecczhm3o6YicvsLXWfh3Ch1W0k8x0nXF+0fFxgt4phz8QvypiwCCFKMqXCnqXExjq10beH+UUA7+nG6mdG/Pu0f3LgFcGrl2s0kNNjpmoJ9o4B29CMO8dMT4Q5ox8uitF6fqsrJOr8qnwNbRzv6hSnG5wP+64C7h9lp30hKNtKdWjtdkbuPA19nJ7Tz3zR/ibgARbhb4AlhavcBebmTHcFl2fvYEnW0ox9xMxKBS8btJ+KiEbq9zA4RthQXDhPa0T9TEe69gWupwc6uBUphquXgf+/FrIjweHQS4/pduMe5ERUMHUd9xv8ZR98CxkS4F2n3EUrUZ10EYNw7BWm9x1GiPssi3GgiGRDKWRYZfXlON+dfNbM+GgIwYdwAAAAASUVORK5CYII=',
    iconSize: [25,41],
    iconAnchor: [12,41]
  })

  return <Map
      ref={mapEl}
      center={props.center}
      zoom={props.zoomLevel}
      crs={crs27700}
      attributionControl={false}
      zoomControl={false}
      zoomAnimation={false}
      onmove={handleMove}
      onzoomend={handleMove}
      ondragend={handleDragEnd}
      >
      <ZoomControl position={'topright'} />
      <TileLayer url={url} minZoom={0} />
      {props.hasMarker && <Marker position={props.center} icon={icon} />}
      { props.children }
    </Map>
}

export default OsMap
