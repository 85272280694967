import React from 'react';
import './Transparency.sass';
import { IonItem, IonLabel, IonRange } from '@ionic/react'

interface TransparencyProps {
  transparency: number
  setTransparency: (n: number) => void
}

const Transparency: React.FC<TransparencyProps> = (props) => {
  return (
    <IonItem className="transparency-item">
      <IonRange
        min={0}
        max={100}
        color="primary"
        value={props.transparency}
        onIonChange={e => props.setTransparency(e.detail.value as number)}>
        <IonLabel slot="start">Places</IonLabel>
        <IonLabel slot="end">Wetness</IonLabel>
      </IonRange>
    </IonItem>
  )
}

export default Transparency;
