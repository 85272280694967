import React from 'react';
import { Plugins } from '@capacitor/core';
import { IonFab, IonFabButton, IonIcon } from '@ionic/react';
import { locateOutline } from 'ionicons/icons';
import proj4 from 'proj4'

const { Geolocation } = Plugins;
interface MapProps {
  locationAction: any
  zoomAction: any
  setTimeseries?: any
}

const projName = 'EPSG:27700'
const projParams = '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 +x_0=400000 +y_0=-100000 +ellps=airy +datum=OSGB36 +units=m +no_defs'
proj4.defs(projName,projParams)

const LocationButton: React.FC<MapProps> = (props) => {
	const defaultLocationZoomLevel = 9;

	const getUserLocation = async () => {
    const coordinates = await Geolocation.getCurrentPosition();
		props.locationAction([coordinates.coords.latitude,coordinates.coords.longitude]);
    props.zoomAction(defaultLocationZoomLevel);
    const converter = proj4('EPSG:27700')
    const bngpoint = converter.forward([coordinates.coords.longitude,coordinates.coords.latitude])
    if(props.setTimeseries){
      props.setTimeseries({x:bngpoint[0], y:bngpoint[1]})
    }

	}
  return (
    <IonFab vertical="bottom" horizontal="end" slot="fixed" className='locate-button'>
      <IonFabButton size="small" onClick={() => getUserLocation()} color="light">
	      <IonIcon icon={locateOutline} />
      </IonFabButton>
    </IonFab>
  )
}

export default LocationButton;  
