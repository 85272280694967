import React from 'react';
import './Footer.sass'
import { IonFooter, IonToolbar } from '@ionic/react'

const Footer = () => {
  return (
    <IonFooter>
      <IonToolbar>
        <img src="../assets/img/UKCEHSoilWetness.png" alt="Soil wetness logo" />
      </IonToolbar>
    </IonFooter>
  )
}

export default Footer;
