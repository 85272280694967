import { IonCardContent, IonCardTitle, IonCard, IonCardHeader, IonContent, IonPage } from '@ionic/react'
import React from 'react'
import './Home.sass'
import { Footer } from '../../components'
import Splash from '../../components/Splash'

const Home: React.FC = () => {
  return (
    <IonPage className="home">
      <IonContent fullscreen>
        <Splash />
        <IonCard>
          <IonCardHeader>
            <IonCardTitle class="ion-text-center">
              Soil Wetness Explorer
            </IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <p>This app shows the “wetness” of the soil (soil moisture storage) across Britain as estimated by a hydrological model.</p>
            <p><img className='splash' src="../assets/img/alans-gate-1.jpg" alt="A water logged gateway in farmer's field"/></p>
            <p>Additional details on the hydrological conditions at the start of the month are available in the Hydrological Outlook (<a href='https://hydoutuk.net/current-conditions'>https://hydoutuk.net/current-conditions</a>).</p>
          </IonCardContent>
        </IonCard>
      </IonContent>
      <Footer />
    </IonPage>
  )
}

export default Home
